<template lang="pug">
AppTable.app-table(:columns="columns", :data="listData", border)
  template(slot-scope="{ row, index }", slot="rank")
    span {{ index + 1 }}
  template(slot-scope="{ row, index }", slot="operator")
    Row
      Col(span="20")
        span.pointer(
          @click="searchOperator(row.id)"
        ) {{ row.fullname }}
      Col(span="4")
        Button.action(
          size="small"
          icon="md-pricetags"
          :to="toPath('betrecord', row)"
        )
  template(slot-scope="{ row, index }", slot="bet")
    span {{ currencyFormat(row.bet, userInfo) }}
  template(slot-scope="{ row, index }", slot="win")
    span {{ currencyFormat(row.win, userInfo) }}
  template(slot-scope="{ row, index }", slot="averageStake")
    span {{ currencyFormat(row.betCount > 0 ? row.bet / row.betCount : 0, userInfo) }}
  template(slot-scope="{ row, index }", slot="profit")
    span(
      :style="{color: (row.bet - row.win) > 0 ? '#ed4014' : '#19be6b', lineHeight: '48px'}"
    ) {{ currencyFormat(row.bet - row.win, userInfo) }}
  template(slot-scope="{ row, index }", slot="percentage")
    span {{ row.bet > 0 && row.win > 0 ? ((row.bet - row.win) / row.bet * 100).toFixed(3) : (row.bet - row.win) * 100 }}%
  template(slot-scope="{ row, index }", slot="rtp")
    span(
      :style="{color: row.bet === 0 ? row.win > 0 ? '#ed4014' : '#19be6b' : (row.win / row.bet) > 0 ? '#ed4014' : '#19be6b', lineHeight: '48px'}"
    ) {{ row.bet > 0 ? (row.win / row.bet).toFixed(3) : row.win }}
</template>
<script>
import AppTable from '~c/app-table'
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'
import UserRankTable from './user-rank-table'

export default {
  name: 'operator-rank-table',
  components: {
    AppTable
  },
  mixins: [Currency, Thumbnail, Time],
  data () {
    return {
    }
  },
  props: {
    listData: Array,
    userInfo: Object,
    level: String,
    date: String
  },
  watch: {
  },
  computed: {
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: this.$t('Rank'),
          slot: 'rank',
          width: 95,
          align: 'center'
        },
        {
          title: this.$t('Operator ID'),
          key: 'id',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Operator name'),
          slot: 'operator',
          ellipsis: true
        },
        {
          title: this.$t('Total stake'),
          slot: 'bet'
        },
        {
          title: this.$t('Total win amount'),
          slot: 'win'
        },
        {
          title: this.$t('Total bet count'),
          key: 'betCount'
        },
        {
          title: this.$t('Total win count'),
          key: 'winCount'
        },
        {
          title: this.$t('Average stake'),
          slot: 'averageStake'
        },
        {
          title: this.$t('Profit'),
          slot: 'profit'
        },
        {
          title: this.$t('profit percentage'),
          slot: 'percentage'
        },
        {
          title: this.$t('RTP'),
          slot: 'rtp'
        },
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(UserRankTable, {
              props: {
                operatorData: params.row,
                userInfo: this.userInfo,
                level: this.level,
                date: this.date
              }
            })
          }
        }
      ]

      return columns
    }
  },
  methods: {
    searchOperator (operator) {
      this.$emit('after-click-operator', operator)
    },
    toPath (path, { id }) {
      return `/${path}?operator=${id}`
    }
  },
  mounted () {
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
.ivu-row
  line-height: 48px
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
