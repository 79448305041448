<template lang="pug">
#statistic-report
  Spin(
    fix
    v-if="isLoading"
  )
    Icon(type="ios-loading", size=18 class="spin-icon")
  Content.app-content
    Row
      Col(span='24')
        //- Select.app-tool(
        //-   v-model="game"
        //-   style="width:140px"
        //-   prefix="ios-game-controller-a"
        //-   @on-change="onChangeGame"
        //-   filterable
        //- )
        //-   Option(v-for="item in allGamesOptions" :value="item.value" :key="item.value") {{ item.label }}
        .daterange(style="margin: -56px 0 0 0; float: right;")
          Button(
            type="primary"
            style="margin: 0 0 0 6px;"
            @click="onGetNextDay(-1)"
          )
            Icon(type="ios-arrow-back" style="margin-right: 4px; margin-left: -6px;")
            span {{ $t("One day before") }}
          DatePicker(
            type="daterange"
            :options="daterangeOptions"
            placement="bottom-end"
            :placeholder="$t('Select date')"
            :format="calendarFormat"
            v-model="selectedDateRange"
            style="width: 200px; margin: 0 0 0 6px;"
            @on-change="onDateRangeChange"
          )

          Button(
            type="primary"
            style="margin: 0 0 0 6px;"
            @click="onGetNextDay(1)"
          )
            span {{ $t("One day after") }}
            Icon(type="ios-arrow-forward" style="margin-left: 4px; margin-right: -6px;")
        //- DatePicker(
        //-   v-model="selectDate"
        //-   :type="calendarType"
        //-   :value="selectDate"
        //-   :format="calendarFormat"
        //-   :placeholder="$t('Select date')"
        //-   placement="bottom-end"
        //-   @on-change="onDateChange"
        //- )

    Row(:gutter="8")
      Col.app-content(span='24' style="margin-top: 8px")
        .no-data-found(v-if="!report")
          span {{ $t("No data found") }}
        .info-body(v-if="report")
          Row(:gutter="8")
            Col(span='6')
              GameStatisticsInfo(
                :title="dateTitle"
                :hide-online-info="true"
                :rank-info="report"
                :report-user="reportUser"
                :periods="chartStep"
                :step="chartStep"
                style="margin-bottom: 10px;"
              )

            Col(span='18')
              Card(class="long" dis-hover)
                p(slot="title")
                  span {{$t('Report profit variety chart')}}
                LineChart(
                  :x-offset="1"
                  :step="chartStep"
                  :data-source="report"
                  :legend="[$t('total bet amount'), $t('total win amount'), $t('profit')]"
                  :lineKeys="['Bet', 'Win', 'Profit']"
                  :level="levelType"
                )

          //- Row(id="operators" :gutter="8" style="margin: 16px 0")
          //-   Col(span="24")
          //-     h1 {{ $t("Operator rank") }}
          //-     OperatorRankTable(
          //-       @after-click-operator="afterClikcOperator"
          //-       :list-data="operatorRanks"
          //-       :user-info="userInfo"
          //-       :level="levelType"
          //-       :date="date"
          //-     )

          //- Row(id="games" :gutter="8" style="margin: 16px 0")
          //-   Col(span="24")
          //-     h1 {{ $t("Game rank") }}
          //-     GameRankTable(
          //-       @after-click-gameName="afterClickGameName"
          //-       :list-data="gameRanks"
          //-       :user-info="userInfo"
          //-     )

          //- Row(id="users" :gutter="8" style="margin: 16px 0")
          //-   Col(span="24")
          //-     h1 {{ $t("User rank") }}
          //-     UserRankTable(
          //-       :list-data="userRanks"
          //-       :user-info="userInfo"
              )
  .anchor
    h3.pointer(
      v-for="(item, index) in scrollList"
      :key="index" @click="scrollTo(`${item.name}`)"
    ) {{ $t(`${item.title}`) }}
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import LineChart from '~c/line-chart'
import GameStatisticsInfo from '~c/game-statistics-info'
import GameRankTable from '~c/game-rank-table'
import RouterEvents from '~m/router-events'
import OperatorRankTable from '~v/statistics/operator-rank-table'
import UserRankTable from '~v/statistics/user-rank-table'

const defaultProfitData = () => {
  return {
    bet: 0,
    win: 0,
    betCount: 0,
    winCount: 0,
    userCount: 0,
    roomCount: 0,
    player: 0,
    period: 0,
    android: 0,
    ios: 0,
    web: 0,
    wap: 0,
    unknown: 0,
    name: 0
  }
}
export default {
  name: 'statisticsReport',
  components: {
    GameStatisticsInfo,
    LineChart,
    GameRankTable,
    OperatorRankTable,
    UserRankTable
  },
  mixins: [RouterEvents],
  data () {
    return {
      selectedDateRange: [new Date(), new Date()],
      selectDate: new Date(),
      provider: 'all',
      game: 'all',
      gametype: 'all',
      search: '',
      profitData: defaultProfitData(),
      sdate: '',
      edate: '',
      defaultOperators: {
        value: 'all',
        label: this.$t('All operators')
      },
      defaultProviders: {
        value: 'all',
        label: this.$t('All providers')
      },
      defaultGames: {
        value: 'all',
        label: this.$t('All games')
      },
      gameNames: [],
      isLoading: false,
      scrollList: [
        { name: 'operators', title: 'Operator rank' },
        { name: 'games', title: 'Game rank' },
        { name: 'users', title: 'User rank' }
      ]
    }
  },
  watch: {
    // 監聽網址的變化進行 data 重設
    '$route' (nv, ov) {
      this.resetSelector()
    }
    // ,
    // sdate (nv, ov) {
    //   this.sdate = nv
    // },
    // edate (nv, ov) {
    //   this.envDate = nv
    // }
  },
  computed: {
    ...mapGetters('statistic', [
      'userDistribution',
      'onlineUserAmount',
      'gameStatistics',
      'operatorStatistics',
      'gameRanks',
      'operatorRanks',
      'report',
      'userRanks',
      'reportUser'
    ]),
    currentDateRange () {
      const [sd, ed] = this.selectedDateRange
      let { sdate, edate } = this.$route.query
      if (!sdate) sdate = moment(sd).format('YYYY/MM/DD')
      if (!edate) edate = moment(ed).format('YYYY/MM/DD')
      return [sdate, edate]
    },
    dateTitle () {
      const [sdate, edate] = this.currentDateRange
      if (sdate === edate) return moment(sdate).format('YYYY/MM/DD')
      return moment(sdate).format('YYYY/MM/DD') + ' - ' + moment(edate).format('YYYY/MM/DD')
    },
    chartStep () {
      switch (this.type) {
        case 'daily':
          const [sdate, edate] = this.currentDateRange
          const diff = (moment(edate).endOf('day').diff(moment(sdate).startOf('day'), 'hour')) + 1
          return diff
        case 'monthly':
          return moment(this.sdate, 'YYYY/MM').daysInMonth()
      }
      return 24
    },
    type () {
      const { type } = this.$route.params
      return type || 'daily'
    },
    levelType () {
      return this.type === 'daily' ? 'hour' : 'day'
    },
    calendarType () {
      if (this.type === 'daily') {
        return 'date'
      } else {
        return 'month'
      }
    },
    calendarFormat () {
      if (this.type === 'daily') {
        return 'yyyy/MM/dd'
      } else {
        return 'yyyy/MM'
      }
    },
    dateFormat () {
      let format = ''
      if (this.type === 'daily') {
        format = 'YYYY-MM-DD'
      } else {
        format = 'YYYY-MM'
      }
      return format
    },
    dataperiods () {
      let time = moment(this.selectDate.getTime())
      return (this.type === 'daily') ? 24 : time.daysInMonth()
    },
    timeUnit () {
      return (this.type === 'daily') ? 'day' : 'month'
    },
    isShowOperator () {
      let { isAdministrator, isSuperOperator, role } = this.userInfo
      return isAdministrator || role === 'Administrator' || isSuperOperator
    },
    periods () {
      return this.type === 'daily' ? 24 : moment(this.selectDate).daysInMonth()
    }
  },
  async mounted () {
    // window.addEventListener('scroll', this.onScroll)
    this.resetSelector()
  },
  methods: {
    ...mapActions('statistic', ['getReport']),
    // 依據 route 重設所有過濾條件
    resetSelector () {
      let { provider, game, sdate, edate, gametype, search } = this.$route.query
      this.provider = provider || 'all'
      this.game = game || 'all'
      this.gametype = gametype || 'all'
      this.search = search || null
      if (!sdate) sdate = moment().format(this.dateFormat)
      if (!edate) edate = moment().format(this.dateFormat)
      // sdate = sdate.substring(0, this.dateFormat.length)
      // edate = edate.substring(0, this.dateFormat.length)
      this.selectedDateRange = [sdate, edate]
      // this.date = date
      // this.selectDate = moment(date, this.dateFormat).toDate()
      this.fetchData()
    },
    async fetchData () {
      let { query } = this.$route
      let { operator, provider, game, sdate, edate, gametype, search } = query

      if (this.selectedOperator && !operator) {
        return this.onOperatorChange(this.selectedOperator.id)
      }

      operator = operator === 'all' ? '' : operator
      provider = provider === 'all' ? '' : provider
      game = game === 'all' ? '' : game
      gametype = gametype === 'all' ? '' : gametype

      sdate = sdate || moment(this.selectedDateRange[0]).format(this.dateFormat)
      edate = edate || moment(this.selectedDateRange[1]).format(this.dateFormat)

      let queryData = {
        provider: provider,
        sdate,
        edate,
        game,
        level: this.levelType,
        gametype,
        search
      }
      this.isLoading = true

      await this.getReport({
        operator,
        query: queryData
      })
      this.isLoading = false
    },
    // onScroll () {
    //   const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    //   if (currentScrollPosition > 390) {
    //     document.querySelector('.anchor').style.display = 'block'
    //   }
    //   if (currentScrollPosition <= 390) {
    //     document.querySelector('.anchor').style.display = 'none'
    //   }
    // },
    scrollTo (name) {
      const element = document.getElementById(name)
      const yOffset = element.getBoundingClientRect().top + window.pageYOffset + -70
      window.scrollTo({ top: yOffset, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="sass" scoped>
#statistic-report
  .statistical
    margin: 5px
    letter-spacing: 3px
    span
      margin-right: 20px
    .show-text
      display: block
      text-align: right
      font-size: 25px
      color: #2b85e4
  .groupplayer
    font-size: 16px
    span
      margin-left: 10px
      letter-spacing: 3px
    .playerCount
      color: #2b85e4

  .loading
    opacity: 0.5

  .no-data-found
    text-align: center

.anchor
  display: none
  // min-width: 200px
  // max-width: 200px
  position: fixed
  right: 90px
  bottom: 30px
  z-index: 10
  // text-align: right
  background-color: rgba(0,0,0,.6)
  padding: 8px 16px
  border-radius: 3px
  box-shadow: 0 1px 3px rgba(0, 0, 0, .2)
  &:hover
    background-color: rgba(0,0,0,.7)

  .pointer
    color: #fff
    font-size: 14px
    font-weight: normal
    display: block
    margin-top: 3px
    cursor: pointer
    &:hover
      color: #ccc

  .pull-right
    float: right
    margin-top: -34px

  .daterange

</style>
