<template lang="pug">
.userRank(v-if="userData && !isLoading")
  AppTable.app-table(:columns="columns", :data="userData", border)
    template(slot-scope="{ row, index }", slot="rank")
      span {{ index + 1 }}
    template(slot-scope="{ row, index }", slot="operator")
      span.pointer(
        @click="searchOperator(row.operatorId)"
      ) {{ row.operator }}
    template(slot-scope="{ row, index }", slot="user")
      Row
        Col(span="22")
          span.pointer(
            @click="searchUser(row.username)"
          ) {{ row.fullname ? row.fullname[0] === row.username ? row.username : `${row.username} / ${row.fullname[0]}` : row.username }}
        Col(span="2")
          Button.action(
            size="small"
            icon="md-pricetag"
            :to="toPath('betrecord', row)"
          )
    template(slot-scope="{ row, index }", slot="totalBet")
      span {{ currencyFormat(row.totalBet) }}
    template(slot-scope="{ row, index }", slot="totalWin")
      span {{ currencyFormat(row.totalWin, userInfo) }}
    template(slot-scope="{ row, index }", slot="betCount")
      span {{ row.betCount }}
    template(slot-scope="{ row, index }", slot="profit")
      span(
        :style="{color: (row.totalBet - row.totalWin) > 0 ? '#ed4014' : '#19be6b', lineHeight: '48px'}"
      ) {{ currencyFormat(row.totalBet - row.totalWin, userInfo) }}
    template(slot-scope="{ row, index }", slot="totalTime")
      span {{ timeDuration(row.totalTime) }}
  .showMore
    Button(
      type="primary"
      shape="circle"
      v-if="showMoreButton"
      @click="showMore"
    ) {{$t('More')}}

Col.table-loading-col(span='24', v-else)
    Spin(fix, :style="{ backgroundColor: 'transparent', marginTop: '0' }")
      Icon(type="ios-loading" size=18 class="spin-icon")
</template>
<script>
import AppTable from '~c/app-table'
import { mapActions, mapGetters } from 'vuex'
import UserRankInfo from '~v/statistics/user-rank-info'
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'
import moment from 'moment'

export default {
  name: 'betrecord-table',
  components: {
    AppTable
  },
  mixins: [Currency, Thumbnail, Time],
  data () {
    return {
      totalSize: 10,
      loadMoreSize: 10,
      isLoading: false
    }
  },
  props: {
    operatorData: Object,
    listData: Array,
    userInfo: Object,
    level: String,
    date: String
  },
  watch: {
  },
  computed: {
    ...mapGetters('userRank', [ 'userRanks' ]),
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: this.$t('Rank'),
          slot: 'rank',
          width: 95,
          align: 'center'
        },
        {
          title: this.$t('User id'),
          key: '_id',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Operator'),
          slot: 'operator',
          align: 'center'
        },
        {
          title: `${this.$t('Username')} / ${this.$t('User name')}`,
          slot: 'user',
          ellipsis: true,
          width: 200
        },
        {
          title: this.$t('Total stake'),
          slot: 'totalBet'
        },
        {
          title: this.$t('Total win amount'),
          slot: 'totalWin'
        },
        {
          title: this.$t('Total bet count'),
          slot: 'betCount'
        },
        {
          title: this.$t('Profit'),
          slot: 'profit'
        },
        {
          title: this.$t('Total time'),
          slot: 'totalTime'
        },
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(UserRankInfo, {
              props: {
                userRankInfo: params.row.games,
                userInfo: this.userInfo
              }
            })
          }
        }
      ]

      return columns
    },
    userData () {
      let userData
      if (this.operatorData) {
        userData = this.userRanks.slice(0, this.totalSize)
      } else {
        userData = this.listData.slice(0, this.totalSize)
      }
      return userData
    },
    showMoreButton () {
      let showMoreButton
      if (this.operatorData) {
        if (this.userData.length !== this.userRanks.length) showMoreButton = true
        if (this.userData.length === this.userRanks.length) showMoreButton = false
      } else {
        if (this.userData.length !== this.listData.length) showMoreButton = true
        if (this.userData.length === this.listData.length) showMoreButton = false
      }
      return showMoreButton
    }
  },
  methods: {
    ...mapActions('userRank', [ 'getReport' ]),
    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },
    searchOperator (operator) {
      let { path, query } = this.$route
      query.operator = operator
      this.updateRoute({ path, query })
    },
    searchUser (fullname) {
      let { path, query } = this.$route
      query.search = fullname
      this.updateRoute({ path, query })
    },
    showMore () {
      if (this.operatorData) {
        this.totalSize = this.loadMoreSize + this.userData.length
      } else {
        this.totalSize = this.loadMoreSize + this.userData.length
      }
    },
    async fetchData (operator) {
      const { query } = this.$route
      let { provider, game, date, gametype, search } = query
      operator = operator === 'all' ? '' : operator
      provider = provider === 'all' ? '' : provider
      game = game === 'all' ? '' : game
      gametype = gametype === 'all' ? '' : gametype
      date = date || this.date

      let queryData = {
        provider: provider,
        date,
        game,
        level: this.level,
        gametype,
        search
      }
      this.isLoading = true

      await this.getReport({
        operator,
        query: queryData
      })
      this.isLoading = false
    },
    toPath (path, row) {
      let { query, params } = this.$route
      if (params.type === 'monthly') query.date = moment(query.date).startOf('month').format('YYYY-MM-DD')
      const date = query.date || moment().startOf('day').format('YYYY-MM-DD')
      return `/${path}?search=${row.username}&date=${date}`
    }
  },
  async mounted () {
    if (this.operatorData) await this.fetchData(this.operatorData.id)
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
.ivu-row
  line-height: 48px
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
.showMore
  margin-top: 10px
  text-align: center
</style>
