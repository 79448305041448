<template lang="pug">
  .table(v-if="userRankInfo")
    .userRankInfo
      AppTable.app-table(:columns="columns", :data="gameData")
        template(slot-scope="{ row, index }", slot="name")
          Avatar(:src="getGameThumb(row, userInfo)", shape="square", size='small', :style="{marginRight: '8px'}")
          span {{ row.name }}
        template(slot-scope="{ row, index }", slot="createdAt")
          span {{ timeFormat(row.createdAt) }}
        template(slot-scope="{ row, index }", slot="updatedAt")
          span {{ timeFormat(row.updatedAt) }}
        template(slot-scope="{ row, index }", slot="totalBet")
          span {{ currencyFormat(row.totalBet, userInfo) }}
        template(slot-scope="{ row, index }", slot="totalWin")
          span {{ currencyFormat(row.totalWin, userInfo) }}
        template(slot-scope="{ row, index }", slot="betCount")
          span {{ row.betCount }}
        template(slot-scope="{ row, index }", slot="profit")
          span(
              :style="{color: (row.totalBet - row.totalWin) > 0 ? '#ed4014' : '#19be6b', lineHeight: '48px'}"
            ) {{ currencyFormat(row.totalBet - row.totalWin, userInfo) }}
        template(slot-scope="{ row, index }", slot="totalTime")
          span {{ timeDuration(row.totalTime) }}
    .showMore
      Button(
        type="primary"
        shape="circle"
        v-if="showMoreButton"
        @click="showMore"
      ) {{$t('More')}}
</template>
<script>
import AppTable from '~c/app-table'
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'

export default {
  name: 'betrecord-table',
  components: {
    AppTable
  },
  mixins: [Currency, Thumbnail, Time],
  data () {
    return {
      totalSize: 10,
      loadMoreSize: 20
    }
  },
  props: {
    userRankInfo: Array,
    userInfo: Object
  },
  watch: {
  },
  computed: {
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: this.$t('Game id'),
          key: 'gameId',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Start at'),
          slot: 'createdAt',
          align: 'center',
          width: 150,
          ellipsis: true
        },
        {
          title: this.$t('End at'),
          slot: 'updatedAt',
          align: 'center',
          width: 150,
          ellipsis: true
        },
        {
          title: this.$t('Game info'),
          slot: 'name',
          ellipsis: true
        },
        {
          title: this.$t('Total stake'),
          slot: 'totalBet'
        },
        {
          title: this.$t('Total win amount'),
          slot: 'totalWin'
        },
        {
          title: this.$t('Total bet count'),
          slot: 'betCount'
        },
        {
          title: this.$t('profit'),
          slot: 'profit'
        },
        {
          title: this.$t('Total time'),
          slot: 'totalTime',
          width: 150
        }
      ]

      return columns
    },
    gameData () {
      let gameData = this.userRankInfo.slice(0, this.totalSize)
      return gameData
    },
    showMoreButton () {
      let showMoreButton = false
      if (this.gameData.length !== this.userRankInfo.length) showMoreButton = true
      if (this.gameData.length === this.userRankInfo.length) showMoreButton = false
      return showMoreButton
    }
  },
  methods: {
    showMore () {
      if (this.operatorData) {
        this.totalSize = this.loadMoreSize + this.gameData.length
      } else {
        this.totalSize = this.loadMoreSize + this.gameData.length
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
.showMore
  margin-top: 10px
  text-align: center
.userRankInfo
  border-radius: 4px
  border: 1px solid #dcdee2
  border-bottom: 0
  border-right: 0
  .ivu-table-overflowX
    overflow-x: hidden
</style>
