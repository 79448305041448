<template lang="pug">
AppTable.app-table(:columns="columns", :data="listData", border)
  template(slot-scope="{ row, index }", slot="rank")
    span {{ index + 1 }}
  template(slot-scope="{ row, index }", slot="info")
    Avatar(:src="getGameThumb(row.game, userInfo)", shape="square", size='small', :style="{marginRight: '8px'}")
    span.pointer(
      @click="searchGame(row.game.gameId)"
    ) {{ row.game.name }}
  template(slot-scope="{ row, index }", slot="bet")
    span {{ currencyFormat(row.bet, userInfo) }}
  template(slot-scope="{ row, index }", slot="win")
    span {{ currencyFormat(row.win, userInfo) }}
  template(slot-scope="{ row, index }", slot="averageStake")
    span {{ currencyFormat(row.bet / row.betCount, userInfo) }}
  template(slot-scope="{ row, index }", slot="profit")
    span(
      :style="{color: (row.bet - row.win) > 0 ? '#ed4014' : '#19be6b', lineHeight: '48px'}"
    ) {{ currencyFormat(row.bet - row.win, userInfo) }}
  template(slot-scope="{ row, index }", slot="rtp")
    span(
      :style="{color: row.bet === 0 ? row.win > 0 ? '#ed4014' : '#19be6b' : (row.win / row.bet) > 0 ? '#ed4014' : '#19be6b', lineHeight: '48px'}"
    ) {{ row.bet > 0 ? (row.win / row.bet).toFixed(3) : row.win }}
</template>
<script>
import AppTable from '~c/app-table'
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'

export default {
  name: 'betrecord-table',
  components: {
    AppTable
  },
  mixins: [Currency, Thumbnail, Time],
  data () {
    return {
    }
  },
  props: {
    listData: Array,
    userInfo: Object
  },
  watch: {
  },
  computed: {
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: this.$t('Rank'),
          slot: 'rank',
          width: 95,
          align: 'center'
        },
        {
          title: this.$t('Game id'),
          key: 'gameId',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Game info'),
          slot: 'info',
          ellipsis: true
        },
        {
          title: this.$t('Total stake'),
          slot: 'bet'
        },
        {
          title: this.$t('Total win amount'),
          slot: 'win'
        },
        {
          title: this.$t('Total bet count'),
          key: 'betCount'
        },
        {
          title: this.$t('Total win count'),
          key: 'winCount'
        },
        {
          title: this.$t('Total user count'),
          key: 'userCount'
        },
        {
          title: this.$t('Total room count'),
          key: 'roomCount'
        },
        {
          title: this.$t('Average stake'),
          slot: 'averageStake'
        },
        {
          title: this.$t('Profit'),
          slot: 'profit'
        },
        {
          title: this.$t('Game RTP'),
          slot: 'rtp'
        }
      ]

      return columns
    }
  },
  methods: {
    searchGame (gameId) {
      this.$emit('after-click-gameName', gameId)
    }
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
